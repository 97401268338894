<template>
    <div class="container">
        <div class="modal--view" :class="{'alerted' : isAlert === 1, 'alerted2' : isAlert === 2}" v-show="!isLoading">
            <div class="b-carousel js-carousel">
                <div class="b-carousel__wrap js-carousel__wrap">
                    <div class="b-carousel__item fade-in">
                        <div class="interview-first-step--container" :class="{'alerted' : isAlert}">
                            <div class="interview--container">
                                <div class="info--container">
                                    <div class="avatar--container">
                                        <div class="outer--gradient">
                                            <div class="inner--gradient">
                                                <img class="avatar__img" :src="schoolAvatar" draggable="false">
                                            </div>
                                        </div>
                                    </div>
                                    <div class="text--container">
                                        <p class="name__text">{{profileSchool.name}}</p>
                                        <p class="class__text">{{cls.fullName}} {{ (profileSchool.type === 1) ? "группа" : "класс"}}</p>
                                    </div>
                                </div>
                                <h1>Карточка родителя</h1>
                                <h3>Ваши данные используются для защиты личного кабинета</h3>
                                <div class="alert--container" v-if="isAlert">
                                    <alert-text :value="alertText"></alert-text>
                                </div>
                                <div class="input--container">
                                    <material-input id="email1-input" type="text" label="E-MAIL" v-model="email" :is-trim-enable="true"></material-input>
                                </div>
                                <div class="input--container">
                                    <material-input ref="phoneInput" id="pho-input" type="text" label="НОМЕР ТЕЛЕФОНА" v-model="phone" :is-trim-enable="true"></material-input>
                                </div>
                                <div class="buttons--container">
                                    <material-button color="white-circle" size="xs" value="ВЫХОД" @click.native="exit"></material-button>
                                    <material-button color="pink-gradient" size="xs" value="ДАЛЕЕ" @click.native="next1step"></material-button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="b-carousel__item fade-out-left">
                        <div class="interview-third-step--container" :class="{'alerted' : isAlert}">
                            <div class="interview--container">
                                <h1>Карточка родителя</h1>
                                <h3>Последний этап</h3>
                                <div class="alert--container" v-if="isAlert">
                                    <alert-text :value="alertText"></alert-text>
                                </div>
                                <div class="input--container">
                                    <material-input id="firstName" type="text" label="ИМЯ" v-model="firstName" :is-trim-enable="false"></material-input>
                                </div>
                                <div class="input--container">
                                    <material-input id="lastName" type="text" label="ФАМИЛИЯ" v-model="lastName" :is-trim-enable="false"></material-input>
                                </div>
                                <div class="checkbox--container">
                                    <div class="title--container">
                                        <p :class="{'selected' : parentStatus.length > 0}">ВЫ ДЛЯ РЕБЕНКА:</p>
                                    </div>
                                    <div class="checkboxes--container">
                                        <input type="radio"  name="radiogender" id="checkboxOne" value="папа" v-model="parentStatus"><label for="checkboxOne">ПАПА</label>
                                        <input type="radio" name="radiogender" id="checkboxTwo" value="мама" v-model="parentStatus"><label for="checkboxTwo">МАМА</label>
                                        <input type="radio" name="radiogender" id="checkboxThree" value="другое" v-model="parentStatus"><label for="checkboxThree">ДРУГОЕ</label>
                                    </div>
                                </div>
                                <div class="buttons--container">
                                    <material-button color="white-circle" size="xs" value="НАЗАД" @click.native="previous"></material-button>
                                    <material-button color="pink-gradient" size="xs" value="ГОТОВО" @click.native="finalStep"></material-button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="auth--loader" v-if="isLoading">
            <video-loader></video-loader>
        </div>

    </div>
</template>

<script>

    import MaterialButton from "@/components/button/MaterialButton";
    import MaterialInput from "@/components/input/MaterialInput";
    import AlertText from "@/components/alert/AlertText";
    import VideoLoader from "@/components/loader/VideoLoader";
    export default {
        name: 'InterviewView',
        components: {VideoLoader, AlertText, MaterialInput, MaterialButton},
        data(){
            return {
                privates: {},
                carousel: {},
                position: 0,
                isAlert: 0,
                alertText: "",
                email: "",
                phone: "",
                login: "",
                password: "",
                repeatPassword: "",
                lastName: "",
                firstName: "",
                parentStatus: "",
                profileSchool:  {},
                cls:{},
                isLoading: false
            }
        },
        computed:{
            schoolAvatar(){
                if (this.profileSchool.avatar === null)
                    return ""
                return this.profileSchool.avatar
            },

        },
        methods:{
            next1step(){
                // this.phone = this.phone.substr(0,16)
                if(this.email.length === 0 || !this.isValidEmail(this.email)){
                    this.alertText = "E-mail введен некорректно"
                    this.isAlert = 1
                } else if (!this.isValidPhone(this.phone)){
                    this.alertText = "Некорректный номер"
                    this.isAlert = 1
                }else {
                    this.isAlert = 0
                    this.next()
                }
            },

            finalStep(){
                if (this.firstName.length === 0){
                    this.alertText = "Имя не должно быть пустым"
                    this.isAlert = 3
                } else if (this.lastName.length === 0){
                    this.alertText = "Фамилия не должна быть пустым"
                    this.isAlert = 3
                } else if (this.parentStatus == ""){
                    this.alertText = "Кем вы приходитесь ребенку?"
                    this.isAlert = 3
                } else {
                    this.onUpdateUserData(this.saveInformation())
                }
            },

            async onUpdateUserData(dataObject){
                await this.$store.dispatch("UPDATE_PARENT_INFO",{dataObject: dataObject})
                await this.$router.push({name: "ParentView"})
            },

            isValidEmail(email){
                const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
                return re.test(String(email).toLowerCase());
            },

            isValidPhone(phone){
                // return phone.length === 16
                return phone.length !== 0
            },

            opened () {
                this.initCarousel({
                    'main': '.js-carousel',
                    'wrap': '.js-carousel__wrap',
                    'prev': '.js-carousel__prev',
                    'next': '.js-carousel__next'
                })
            },

            initCarousel(setting) {
                this.privates = {}

                /* Privates properties */
                this.privates.setting = setting

                this.privates.sel = {
                    'main': document.querySelector(this.privates.setting.main),
                    'wrap': document.querySelector(this.privates.setting.wrap),
                    'prev': document.querySelector(this.privates.setting.prev),
                    'next': document.querySelector(this.privates.setting.next)
                }

                this.privates.opt = {
                    'position': 0,
                    'max_position': 2
                }
            },

            next() {
                this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
                window.scrollTo(0,0)
                ++this.privates.opt.position
                ++this.position

                if (this.privates.opt.position >= this.privates.opt.max_position) {
                    this.privates.opt.position = 0
                }

                // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
                // setTimeout(() => this.privates.sel.wrap.children[this.privates.opt.position].classList.remove('hidden'), 200)

                this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
                this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")

            },

            previous() {
                this.isAlert = 0
                this.login = ""
                this.password = ""
                this.repeatPassword = ""
                this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-in-right")
                this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-out-left")
                --this.privates.opt.position
                --this.position

                if (this.privates.opt.position < 0) {
                    this.privates.sel.wrap.classList.add('s-notransition')
                    this.privates.opt.position = this.privates.opt.max_position - 1
                }

                // this.privates.sel.wrap.style['transform'] = `translateX(-${this.privates.opt.position}00%)`
                this.privates.sel.wrap.children[this.privates.opt.position].classList.remove("fade-out-left")
                this.privates.sel.wrap.children[this.privates.opt.position].classList.add("fade-in-right")
            },
            async exit(){
                const res = await this.$store.dispatch("EXIT_FROM_ACCOUNT");
                if (res){
                    localStorage.removeItem("accessToken")
                    localStorage.removeItem("refreshToken")
                    this.$router.push({name: "AuthView"})
                }
            },


            saveInformation(){
                const dataObject = {
                    firstName: this.firstName,
                    lastName: this.lastName,
                    parentStatus : this.parentStatus,
                    password : this.password,
                    login : this.login,
                    phone : this.phone,
                    email : this.email
                }

                return dataObject;
            },
            onBeforeOpen(e){
                this.profileSchool = e.params.profileSchool
                this.cls = e.params.class
            }

        },
        async created(){
            try{
                this.isLoading = true
                await this.$store.dispatch("GET_PROFILE")
                // eslint-disable-next-line no-unused-vars
                const profile = this.$store.getters.PROFILE
                await this.$router.push({name: 'ParentView'})
            } catch (e) {
                await this.$store.dispatch("GET_SCHOOL",{ id : e.profile.schoolId})
                const account = this.$store.getters.SCHOOL
                localStorage.setItem("tutorialEnabled","1")

                this.onBeforeOpen({
                    params:{
                        profileSchool : account,
                        class : e.schoolClass
                    }
                })

                this.isLoading = false

            }
        },
        mounted(){
            this.opened()

        }
    }
</script>

<style scoped lang="scss">

    .container{
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #f8f8f8;
    }

    .modal--view{
        background: #FFFFFF;
        border: 0 solid #F2F2F2;



        width: 350px;
        height: 520px;

        &.alerted{
            height: 580px;
        }

        &.alerted2{
            height: 560px;
        }
    }
    .b-carousel {
        position: relative;
        height: 100%;
    }

    .b-carousel__prev,
    .b-carousel__next {
        position: absolute;
        top: 50%;
        left: 20px;
        width: 50px;
        height: 50px;
        background: #fff;
        transform: translateY(-50%) translateZ(0);
        cursor: pointer;
        text-indent: 100%;
        white-space: nowrap;
        z-index: 3;
    }

    .b-carousel__next {
        left: auto;
        right: 20px;
    }

    .b-carousel__wrap {
        display: flex;
        transition: opacity .5s;
        will-change: opacity;
        position: relative;
        z-index: 1;
        height: auto;
    }

    .b-carousel__item {
        flex: 0 0 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
    }

    .over-hidden{
        overflow: hidden;
    }

    .hidden{
        display: none;
    }


    .interview-first-step--container{
        background: #FFFFFF;
        box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
        display: flex;

        &.alerted{
            height: 540px;
        }

        width: 350px;
        height: 520px;


        &.alerted{
            height: 580px;
        }
    }

    .interview-third-step--container{

        background: #FFFFFF;
        box-shadow: 0 2px 7px 1px rgba(88,88,88,0.40);
        display: flex;



        .interview--container h1{
            margin-top: 57px;
        }

        width: 350px;
        height: 520px;


        &.alerted{
            height: 580px;
        }
    }

    .interview--container{
        width: calc(420px - 50px);
        height: 100%;
        margin-left: 35px;




        .info--container{
            margin-top: 38px;
            display: flex;

            .avatar--container{
                .outer--gradient{
                    width: 50px;
                    height: 50px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-radius: 50%;
                    background-image: linear-gradient(-180deg, rgba(255,255,255,0.20) 0%, rgba(255,255,255,0.00) 100%);
                    box-shadow: 0 1px 5px 0 rgba(0,0,0,0.20), 0 1px 2px 0 rgba(0,0,0,0.20), inset 0 1px 0 0 rgba(255,255,255,0.16), inset 0 -1px 0 0 rgba(0,0,0,0.05);
                    .inner--gradient{
                        width: 48px;
                        height: 48px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-image: linear-gradient(-180deg, rgba(255,255,255,0.40) 0%, rgba(255,255,255,0.20) 100%);
                        box-shadow: 0 1px 5px 0 rgba(0,0,0,0.20), 0 1px 2px 0 rgba(0,0,0,0.20), inset 0 1px 0 0 rgba(255,255,255,0.16), inset 0 -1px 0 0 rgba(0,0,0,0.05);

                        .avatar__img{
                            width: 46px;
                            height: 46px;
                            border-radius: 50%;
                        }
                    }
                }
            }

            .text--container{
                margin-top: 3px;
                margin-left: 10px;
                .name__text{
                    font-family: Roboto, "sans-serif";
                    font-size: 14px;
                    color: #000000;
                    letter-spacing: 0;
                    width: 220px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                }

                .class__text{
                    background: #F4F4F4;
                    font-family: Roboto, "sans-serif";
                    font-size: 14px;
                    color: #222222;
                    letter-spacing: 0;
                    text-align: center;
                    text-shadow: 0 0 0 rgba(0,0,0,0.50);
                    border-radius: 13px;
                    height: 26px;
                    margin-top: 7px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 96px;

                    pointer-events: none;
                    -webkit-touch-callout: none; /* iOS Safari */
                    -webkit-user-select: none; /* Safari */
                    -khtml-user-select: none; /* Konqueror HTML */
                    -moz-user-select: none; /* Old versions of Firefox */
                    -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
                }
            }
        }

        h1{
            font-family: Roboto, "sans-serif";
            font-size: 24px;
            color: #000000;
            line-height: 21px;
            letter-spacing: 0;
            margin-top: 25px;
        }

        h2{
            font-family: Roboto, "sans-serif";
            font-weight: 300;
            color: #000000;
            line-height: 21px;
            margin-top: 38px;
            margin-bottom: 5px;
            width: 270px;
            font-size: 13px;
        }

        h3{
            font-family: Roboto, "sans-serif";
            font-weight: 300;
            letter-spacing: 0;
            font-size: 13px;
            color: #000000;
            line-height: 17px;
            margin-top: 5px;
            width: 280px;
        }

        .input--container{
            display: flex;
            margin-top: 23px;
        }

        .buttons--container{
            display: flex;

            flex-direction: column-reverse;
            width: 244px;
            margin-top: 63px;
            justify-content: flex-start;
            margin-left: 14px;
            margin-right: 53px;


            button:first-child{
                margin-top: 20px;
            }

            button:last-child{
                width: 100%;
            }
        }

        .checkbox--container{
            margin-top: 22px;

            .title--container{
                p{
                    color: #C6C6C6;
                    font-size: 12px;
                    transition: 300ms ease all;


                    &.selected{
                        color: #F54E5E;
                    }
                }
            }

            .checkboxes--container{
                margin-top: 18px;
                display: flex;
                margin-left: -19px;

            }
        }
    }



    /* ----------------------------------------------
 * Generated by Animista on 2020-7-14 14:41:35
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation fade-in
     * ----------------------------------------
     */
    @-webkit-keyframes fade-in-right {
        0% {
            -webkit-transform: translateX(50px);
            transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }
    @keyframes fade-in-right {
        0% {
            -webkit-transform: translateX(50px);
            transform: translateX(50px);
            opacity: 0;
        }
        100% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
    }

    /* ----------------------------------------------
 * Generated by Animista on 2020-7-14 14:43:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info.
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

    /**
     * ----------------------------------------
     * animation fade-out-left
     * ----------------------------------------
     */
    @-webkit-keyframes fade-out-left {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
            opacity: 0;
        }
    }
    @keyframes fade-out-left {
        0% {
            -webkit-transform: translateX(0);
            transform: translateX(0);
            opacity: 1;
        }
        100% {
            -webkit-transform: translateX(-50px);
            transform: translateX(-50px);
            opacity: 0;
        }
    }


    .fade-in-right {
        -webkit-animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
        animation: fade-in-right 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    }

    .fade-out-left {
        display: none;
        pointer-events: none;
    }

    label{
        display: inline-block;
        background-color: white;
        border-radius: 25px;
        white-space: nowrap;
        margin-right: 9px;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        -webkit-tap-highlight-color: transparent;
        transition: all .2s;
        font-size: 14px;
        color: #141213;
        text-align: center;
        font-family: Roboto, "sans-serif";
        font-weight: 300;
        border: 1px solid #E7E7E7;
    }


    label:hover{
        background-color: rgba(155,155,155,0.05);
    }


    label {
        width: 100px;
        height: 36px;
        cursor: pointer;
        display: inline-flex;
        align-items: center;
        justify-content: center;
    }




    input[type="radio"]:checked + label {
        transition: all .2s;
        border: 0;
        background: rgba(155,155,155,0.10);
    }

    input[type="radio"] {

    }
    input[type="radio"] {
        position: absolute;
        opacity: 0;
    }

    .alert--container{
        margin-top: 20px;
    }



    .support--container *{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }


    .checkboxes--container{
        -webkit-touch-callout: none; /* iOS Safari */
        -webkit-user-select: none; /* Safari */
        -khtml-user-select: none; /* Konqueror HTML */
        -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
        user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Edge, Opera and Firefox */
    }



    .mob--container{
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: space-between;
        .skip__text{
            display: block;
            @include for-desktop-devices{
                display: none;

            }
            width: 120px;
            margin-left: 32px;
        }
    }

    .auth--loader{
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.16);
        position: absolute;
        z-index: 1;

        div{
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            padding: 0;
        }
    }
</style>
